import {defineStore} from 'pinia';
import {ref, onBeforeMount} from 'vue';

import dayjs from 'dayjs';
import utc from 'dayjs-plugin-utc';

dayjs.extend(utc);

export const useChatStore = defineStore('chat', () => {
            const chatHistories = ref({});
            const selectedCharacter = ref(null);
            const coins = ref(0);
            const messages = ref({});
            const characters = ref([]);
            const traits = ref([]);
            const sessionId = ref(null);
            const sessionIdToChid = ref({});
            const userId = ref(null);
            const coinsBalance = ref(null);
            const characterHints = ref({});
            const coinsWidgetIsLoading = ref(true);
            const isInitialMessage = ref(false);
            const isChatHistoryLoading = ref(false);
            const historyImageIsLoading = ref(false);
            const usersData = ref({})
            const isMarkModeOn = ref(false);
            const isLoading = ref(false);
            const activeSessions = ref({})
            const unreadMessages = ref({})

            const sessionStates = ref({});

            //////////////////////////STAGING LINKS ///////////////////////////////////////////////////////////

            const apiUrl = `https://glst711zo8.execute-api.us-west-2.amazonaws.com/prod` //DEV
            // const apiUrl = `https://api.true-love.ai` //PROD
            const cognitoDomain = `https://true-love-ai-auth-dev.auth.us-west-2.amazoncognito.com` //DEV
            // const cognitoDomain = `https://true-love-ai-auth.auth.us-west-2.amazoncognito.com` //PROD
            const cognitoClientId = '6ljqd9r7qsiub8im7devil99eq' //DEV
            // const cognitoClientId = '4i9jhsj7b8gqs2rvq2nr7rrjom' //PROD
            const snsTopic = "arn:aws:sns:us-west-2:864981716495:ai-nikki-form-submission-topic" //DEV
            // const snsTopic = "arn:aws:sns:us-west-2:147997163581:ai-nikki-form-submission-topic" //PROD
            const websocketUrl = "wss://4kx6o1npke.execute-api.us-west-2.amazonaws.com/Prod/" //DEV
            // const websocketUrl = "wss://44slmagsjk.execute-api.us-west-2.amazonaws.com/Prod/" //PROD
            const fallBackURL = 'https://ai-nikki.sosw.app' // DEV
            // const fallBackURL = 'https://true-love.ai' // PROD

            //////////////////////////END STAGING LINKS ///////////////////////////////////////////////////////////

            // Function to get or initialize session state
            function getSessionState(sessionId) {
                if (!sessionStates[sessionId]) {
                    sessionStates[sessionId] = {
                        isMediaForGeneration: ref(false),
                        loadingChatSessions: ref(new Set()),
                        unreadMessages: ref(0),
                    };
                }
                return sessionStates[sessionId];
            }

            function getApiUrl(endpoint) {
                return `${apiUrl}/${endpoint}`
            }

            // Assigning the character data from session storage to characters array in the store
            function loadCharacters() {
                const storedCharacters = sessionStorage.getItem('characterData');
                if (storedCharacters) {
                    characters.value = JSON.parse(storedCharacters);
                    if (characters.value.length > 0) {
                        selectedCharacter.value = characters.value[0];
                    }
                } else {
                    console.error('No characters found in sessionStorage');
                }
            }

            // Fetching session ID from the API and assigning it to the sessionId in the store
            async function getSessionId(userId, chid, resetSession) {
                try {
                    let url = `${apiUrl}/get-session-id?user_id=${userId}&chid=${chid}`;

                    if (resetSession) {
                        url += `&reset_session=true`;
                    }
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    const data = await response.json();
                    if (data.status === "success") {
                        sessionId.value = data.session_id;
                        // Save the session ID as a key in the sessionIdToChid with the userId and chid as values
                        sessionIdToChid.value[data.session_id] = chid;
                        sessionStorage.setItem('sessionIdToChid', JSON.stringify(sessionIdToChid.value));
                        return data.session_id;
                    } else {
                        console.error("Failed to retrieve session_id:", data);
                    }
                } catch (error) {
                    console.error("Error fetching session_id:", error);
                }
            }


            // Fetching character data from the API, assing them to characters array, 
            // select the first character as selectedCharacter and save them to session storage
            async function fetchCharacterData() {
                try {
                    const response = await fetch(getApiUrl('character'), {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const data = await response.json();
                    let sortedCharacters = [];
                    if (data.Items) {
                        const characterPromises = data.Items.map(async (item) => {

                            return {
                                chid: item.chid.S,
                                name: item.name.S,
                                description: item.description.S,
                                initialMessage: item.initialMessage.S,
                                image: item.profile_image_id?.S || '/images/56467421-af98-4062-8353-39223096d99d.webp',
                                icon: item.avatar_image_id?.S || '/modelIcons/56467421-af98-4062-8353-39223096d99d.webp',
                                displayPosition: parseInt(item.display_position?.N || '0'),
                                styles: item.styles.M || {},
                                traits: Array.isArray(traits) ? traits : [],
                                hints: item.hints_themes ? item.hints_themes.L : []
                            };
                        });

                        sortedCharacters = await Promise.all(characterPromises);
                    } else {
                        console.error("Unexpected data format:", data);
                    }

                    sortedCharacters.sort((a, b) => a.displayPosition - b.displayPosition);
                    characters.value = sortedCharacters;
                    sessionStorage.setItem('characterData', JSON.stringify(sortedCharacters));
                    if (characters.value.length > 0) {
                        selectedCharacter.value = characters.value[0];
                    }
                } catch (error) {
                    console.error("Error fetching character data:", error);
                }
            }

            async function fetchUserData(userId) {
                try {
                    const response = await fetch(`${apiUrl}/get-engagement-data?user_id=${userId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const data = await response.json();

                    if (data.Item) {
                        const userData = {
                            level: data.Item.user_lvl?.N || 1,
                            experience: data.Item.user_exp?.N || 0,
                        };
                        usersData.value = userData;
                    }
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            }


            async function fetchTraitsData(chid, session_id) {
                try {
                    const response = await fetch(getApiUrl('get-traits'), {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            session_id: session_id,
                            chid: chid,
                        })
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const data = await response.json();
                    let traitsData = [];

                    if (Array.isArray(data)) {
                        traitsData = data.map(item => ({
                            tid: item.tid,
                            name: item.name,
                            icon: item.icon || '/icons/traits/99bb5e31-0f77-4efc-b017-987aad240cd9.png',
                            price: item.price || 0
                        }));

                        traitsData.sort((a, b) => a.price - b.price);
                    } else {
                        console.error("Unexpected data format:", data);
                    }

                    traits.value = traitsData;
                    sessionStorage.setItem('traitsData', JSON.stringify(traitsData));

                    return traitsData;

                } catch (error) {
                    console.error("Error fetching traits data:", error);
                }
            }

            function setChatHistory(newChatHistory, charId) {
                if (charId) {
                    chatHistories.value[charId] = newChatHistory;
                    populateMessages();
                    saveChat();
                } else {
                    console.error('Invalid model ID');
                }
            }

            function setUserId(id) {
                userId.value = id;
            }

            function addMessage(message, charId) {
                if (!chatHistories.value[charId]) {
                    chatHistories.value[charId] = [];
                }
                chatHistories.value[charId].push(message);
                updateMessage(charId, new Date(), message.text);
                saveChat();
            }

            function removeTypingIndicator(charId) {
                if (chatHistories.value[charId] && chatHistories.value[charId].length > 0) {
                    const lastMessageIndex = chatHistories.value[charId].length - 1;
                    if (chatHistories.value[charId][lastMessageIndex].text === '...') {
                        chatHistories.value[charId].splice(lastMessageIndex, 1);
                        saveChat();
                    }
                }
            }

            // Load chat history from session storage and assign it to chatHistories
            function loadChat() {
                const savedChat = JSON.parse(sessionStorage.getItem('chatHistories') || '{}');
                chatHistories.value = savedChat;
            }

            function saveChat() {
                sessionStorage.setItem('chatHistories', JSON.stringify(chatHistories.value));
            }

            function clearChatHistory(charId) {
                chatHistories.value[charId] = [];
                updateMessage(charId, '', '');
                saveChat();
            }

            function clearChatHistories() {
                chatHistories.value = {};
                saveChat();
            }

            // Set the selected character in the store and save it to session storage
            function setSelectedCharacter(char) {
                if (char && characters.value.some(c => c.chid === char.chid)) {
                    selectedCharacter.value = char;
                    sessionStorage.setItem('selectedCharacter', JSON.stringify(char));
                }
            }

            function convertTimestampToLocal(timestamp) {
                if (timestamp) {
                    const localTime = dayjs.utc(timestamp).local().format('YYYY-MM-DDTHH:mm:ss');
                    timestamp = localTime;
                }
                return timestamp;
            }

            async function fetchChatHistory(user_id, session_id) {
                try {
                    this.isChatHistoryLoading = true;
                    const url = new URL(getApiUrl(`get-session-context`));

                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            user_id: user_id,
                            session_id: session_id,
                            limit: 100
                        }),
                    });

                    if (!response.ok) {
                        const errorText = await response.text();
                        throw new Error(`Network response was not ok, status: ${response.status}, response text: ${errorText}`);
                    }

                    const data = await response.json();

                    const charId = sessionIdToChid.value[session_id]

                    const currentCharacter = characters.value.find(char => char.chid === charId);

                    if (Array.isArray(data.context) && data.context.length > 0) {

                        chatHistories.value[charId] = [];

                        for (let msg of data.context) {
                            const messageContent = msg.content || '';
                            const mediaId = msg.meta?.media_id?.S || null;
                            const isHint = msg.meta?.hints_label?.S || null;
                            const isTrait = msg.meta?.is_trait?.BOOL || false;
                            const traitImage = msg.meta?.image?.S || null;
                            const isCharacterMessage = msg.role !== 'user';
                            const localTimestamp = await convertTimestampToLocal(msg.timestamp);

                            if (isHint) {
                                const hintMessage = {
                                    sender: currentCharacter.name,
                                    text: messageContent,
                                    timestamp: localTimestamp,
                                    isHint: true,
                                    label: isHint
                                };
                                chatHistories.value[charId].push(hintMessage);
                                continue;
                            }

                            if (isTrait) {
                                const traitMessage = {
                                    sender: msg.role === 'user' ? 'You' : currentCharacter.name,
                                    text: '[You presented a gift]',
                                    timestamp: localTimestamp,
                                    image: traitImage,
                                    isTrait: true,
                                };
                                chatHistories.value[charId].push(traitMessage);
                                continue;
                            }

                            if (isHint) {
                                const hintMessage = {
                                    sender: currentCharacter.name,
                                    text: messageContent,
                                    timestamp: msg.timestamp,
                                    isHint: true,
                                    label: isHint
                                };
                                chatHistories.value[charId].push(hintMessage);
                                continue;
                            }

                            if (isTrait) {
                                const traitMessage = {
                                    sender: msg.role === 'user' ? 'You' : currentCharacter.name,
                                    text: '[You presented a gift]',
                                    timestamp: msg.timestamp,
                                    image: traitImage,
                                    isTrait: true,
                                };
                                chatHistories.value[charId].push(traitMessage);
                                continue;
                            }

                            chatHistories.value[charId].push({
                                sender: msg.role === 'user' ? 'You' : currentCharacter.name,
                                text: messageContent,
                                timestamp: localTimestamp,
                            });

                            if (mediaId && isCharacterMessage) {
                                // Find the index of an existing preview message in chatHistories that has the same image URL
                                const existingMediaIndex = chatHistories.value[charId].findIndex(msg => msg.image === `/preview/${mediaId}.webp`);

                                if (existingMediaIndex === -1) {
                                    const mediaMessage = {
                                        sender: currentCharacter.name,
                                        text: '[Photo]',
                                        timestamp: localTimestamp,
                                        image: `/preview/${mediaId}.webp`,
                                        isBlurred: true, 
                                    };

                                    chatHistories.value[charId].push(mediaMessage);
                                    getMedia(mediaId, charId, currentCharacter);
                                }
                            }
                        }

                        populateMessages();
                        setChatHistory(chatHistories.value[charId], charId);
                        isInitialMessage.value = false;
                    } else {
                        isInitialMessage.value = true;
                    }
                } catch (error) {
                    console.error("Error fetching chat history:", error);
                    isInitialMessage.value = true;
                } finally {
                    this.isChatHistoryLoading = false;
                }
            }

            async function fetchLastMessage(userId, chid) {
                const url = new URL(getApiUrl(`fetch-last-message-to-fe`));
                try {
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            user_id: userId,
                            chid: chid,
                        }),
                    });

                    if (!response.ok) {
                        const errorText = await response.text();
                        console.error("Error fetching last message:", errorText);
                        return null;
                    }
                    const data = await response.json();
                    return data.message || data;
                } catch (error) {
                    console.error("Error in fetchLastMessage:", error);
                    return null;
                }
            }


            function resetInitialMessage() {
                isInitialMessage.value = false;
            }

            async function getMedia(mediaId, charId, currentCharacter) {
                const userId = sessionStorage.getItem("userId");
                const getMediaUrl = `${apiUrl}/get-media?user_id=${userId}&media_id=${mediaId}`;

                try {
                    historyImageIsLoading.value = true;
                    const getResponse = await fetch(getMediaUrl);
                    if (getResponse.status === 460) {
                        historyImageIsLoading.value = false;                      
                        console.warn('Access denied to media. Displaying blurred version.');
                        return;
                    }

                    const base64Image = await getResponse.text();
                    const mimeType = detectMimeType(base64Image);
                    if (!base64Image) throw new Error('Base64 image data is undefined');

                    const imageSrc = `data:${mimeType};base64,${base64Image}`;
                    if (Array.isArray(chatHistories.value[charId])) {
                        const characterMessages = chatHistories.value[charId];

                        const messageIndex = characterMessages.findIndex((msg) => msg.image === `/preview/${mediaId}.webp`);

                        if (messageIndex !== -1) {
                            characterMessages[messageIndex].image = imageSrc;
                            characterMessages[messageIndex].isBlurred = false;
                        }

                    }
                } catch (error) {
                    if (error.status === 460 || (error.response && error.response.status === 460)) {
                        console.warn("Access denied (460): Cannot retrieve the unblurred photo.");
                        return;
                    }
                    console.error('Error in getMedia:', error);
                    addMessage({
                        sender: currentCharacter.name,
                        text: 'Sorry, I could not retrieve the photo.',
                        timestamp: new Date(),
                    }, charId);
                }
                await new Promise(resolve => setTimeout(resolve, 100));
                historyImageIsLoading.value = false;
                saveChat();
            }

            function detectMimeType(base64) {
                if (base64.startsWith('/9j/')) return 'image/jpeg';
                if (base64.startsWith('iVBORw0KGgo')) return 'image/png';
                if (base64.startsWith('R0lGOD')) return 'image/gif';
                return 'image/jpeg';
            }


            async function getBalance(userId, retries = 0, delay = 0) {
                try {
                    const response = await fetch(getApiUrl('get-balance'), {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            user_id: userId,
                        }),
                    });

                    coinsWidgetIsLoading.value = true;

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const data = await response.json();

                    if (data.balance === coinsBalance.value) {
                        if (retries > 0) {
                            setTimeout(() => getBalance(userId, retries - 1, delay), delay);
                        } else {
                            coinsWidgetIsLoading.value = false;
                            console.error("Failed to fetch balance after multiple attempts.");
                        }
                    } else {
                        coinsBalance.value = data.balance;
                        coinsWidgetIsLoading.value = false;
                    }
                } catch (error) {
                    console.error("Error processing transaction:", error);
                }
            }

            // Get selected character from session storage, if it exists, or set it to the first character in the array
            function loadSelectedCharacter() {
                try {
                    const savedChar = JSON.parse(sessionStorage.getItem('selectedCharacter') || '{}');
                    if (savedChar.chid) {
                        const matchingChar = characters.value.find(char => char.chid === savedChar.chid);
                        if (matchingChar) {
                            selectedCharacter.value = matchingChar;
                        } else {
                            selectedCharacter.value = characters.value[0];
                        }
                    } else {
                        selectedCharacter.value = characters.value[0];
                    }
                } catch (error) {
                    selectedCharacter.value = characters.value[0];
                }
            }

            function addCoins(amount) {
                if (amount > 0) {
                    coins.value += amount;
                    saveCoins();
                }
            }

            function spendCoins(amount) {
                if (coins.value >= amount) {
                    coins.value -= amount;
                    saveCoins();
                }
            }

            // Load coins from session storage, if it exists, or set it to 0
            function loadCoins() {
                try {
                    coins.value = JSON.parse(sessionStorage.getItem('coins') || '0');
                } catch (error) {
                    coins.value = 0;
                }
            }

            function saveCoins() {
                sessionStorage.setItem('coins', JSON.stringify(coins.value));
            }

            function truncateMessage(message, maxLength) {
                const strMessage = String(message || '');
                return strMessage.length <= maxLength
                    ? strMessage
                    : strMessage.substring(0, maxLength) + '...';
            }

            function updateMessage(charId, date, preview) {
                if (!messages.value[charId]) {
                    messages.value[charId] = {};
                }
                const maxLength = 40;
                const truncatedPreview = truncateMessage(preview, maxLength);
                messages.value[charId].lastMessageDate = date;
                messages.value[charId].lastMessagePreview = truncatedPreview;
            }

            function getMessage(charId) {
                if (!charId) {
                    return {lastMessageDate: '', lastMessagePreview: ''};
                }
                const message = messages.value[charId];
                if (message) {
                    return message;
                } else {
                    return {lastMessageDate: '', lastMessagePreview: ''};
                }
            }

            // Populate the messages array with the last message for each character
            function populateMessages() {
                const validCharIds = new Set(characters.value.map(char => char.chid));

                Object.keys(chatHistories.value).forEach(charId => {
                    if (validCharIds.has(charId)) {
                        const messagesForCharacter = chatHistories.value[charId];
                        if (messagesForCharacter && messagesForCharacter.length > 0) {
                            const lastMessage = messagesForCharacter[messagesForCharacter.length - 1];
                            updateMessage(charId, lastMessage.timestamp, lastMessage.text);
                        } else {
                            updateMessage(charId, '', '');
                        }
                    }
                });
            }

            onBeforeMount(async () => {
                // Getting characters from session storage
                const storedCharacters = sessionStorage.getItem('characterData');
                // If we don't have characters in session storage, we set it to an empty array
                const parsedCharacters = storedCharacters ? JSON.parse(storedCharacters) : [];
                // If we don't have characters in session storage, we fetch them from the API
                if (!parsedCharacters.length) {
                    await fetchCharacterData();
                } else {
                    // If we have characters in session storage, we assign them to the characters array
                    loadCharacters();
                }

                // Load chat history from session storage if exists
                loadChat();

                // Check if we have a coins in session storage, if not, set it to 0
                loadCoins();

                // Set the selected character in the store to the first character in the characters array or the one saved in session storage
                loadSelectedCharacter();
            });

            return {
                characters,
                loadCharacters,
                chatHistories,
                getApiUrl,
                cognitoDomain,
                cognitoClientId,
                snsTopic,
                websocketUrl,
                setChatHistory,
                addMessage,
                removeTypingIndicator,
                loadChat,
                saveChat,
                clearChatHistory,
                clearChatHistories,
                selectedCharacter,
                setSelectedCharacter,
                loadSelectedCharacter,
                fetchChatHistory,
                fetchTraitsData,
                fetchLastMessage,
                isInitialMessage,
                resetInitialMessage,
                isChatHistoryLoading,
                historyImageIsLoading,
                getBalance,
                coinsBalance,
                coinsWidgetIsLoading,
                addCoins,
                fetchUserData,
                usersData,
                spendCoins,
                loadCoins,
                saveCoins,
                characterHints,
                updateMessage,
                getMessage,
                populateMessages,
                getSessionId,
                setUserId,
                userId,
                sessionId,
                sessionIdToChid,
                isMarkModeOn,
                isLoading,
                sessionStates,
                getSessionState,
                activeSessions, 
                unreadMessages,
                fallBackURL
            };
        }
    )
;
